import styled from "styled-components"

const PodcastStyles = styled("div")`
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  h1 {
    margin-top: 130px;
  }
  height: 100vh;
`

export default PodcastStyles
