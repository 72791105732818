import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PodcastStyles from '../styles/podCastStyles'

const Podcast = () => (
  <Layout>
    <SEO title="Home" />
    <PodcastStyles>
      <h1>Podcast Coming soon!! Check back later</h1>
    </PodcastStyles>
  </Layout>
)

export default Podcast
